import React from 'react'
import styled from 'styled-components'
import categories from './categoriesContent'
import { device } from '../../cors/ResponsiveSettings'
import Container from '../../cors/Container'
import StyledButton from '../../cors/StyledButton'
import StyledLink from '../../cors/StyledLink'
import whoAmI1400Cut from '../../../assets/images/whoAmI_w_1400_cut.jpg'
import votreCoachPic from '../../../assets/images/raphael_coach_sportif.jpeg'
import votreCoachPicSquare from '../../../assets/images/raphael_coach_sportif_square.jpeg'



const YourCoach = ({element}) => {

    const {about} = categories
    return (
        <Wrapper ref={element}>
          <ContentWrapper>
            <ImageWrapper src={votreCoachPic} alt='coach-picture'/>
            <Polaroid>
              <img src={votreCoachPicSquare} alt="your coach" style={{width:"100%"}} />
              <TextContainer>
                <p>Votre coach</p>
              </TextContainer>
            </Polaroid>
          </ContentWrapper>
          <ContentWrapper>
            <Title>{about.title}</Title>
            <p>{about.content1}</p>
            <p>{about.content2}...</p>
            <StyledLink to={about.linkTo}><StyledButton>En savoir plus</StyledButton></StyledLink>
          </ContentWrapper>
        </Wrapper>
    )
}

export default YourCoach


const Wrapper = styled.div`
width:100vw;
height:100vh;
display:flex;
flex-direction: column;
justify-content: center;
padding: 1rem;
align-items: flex-start;
font-size: calc(1rem +0.6vw);
/* position: absolute;
top:0;
z-index:10; */
font-weight:400;
h1{
  font-size: calc(2rem + 0.5vw);
  font-weight: 400;
  padding:0.5rem 1rem;
}
p{
  padding: 0.5rem 1rem;
  /* overflow-x:auto; */
  /* min-height: 7rem; */
}

@media ${device.laptop} {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding:4rem;
  position:relative;
  color:black;
  font-weight:300;
  line-height:2;
  align-items:center;
  text-align:center;

}

@media (max-width:992px) {
  font-size: calc(0.7rem + 0.8vw);
}
`
const Title = styled.h2`
  display: none;

  @media ${device.laptop} {
  display: block;
  }
`

const ContentWrapper = styled(Container)`
max-width: 100%;
height: auto;

@media ${device.laptop} {
  height: 90%;
  width: 50%;
}  
`

const ImageWrapper = styled.img`
display: none;
max-width: 65%;
height: auto;
border-radius: 8px;

@media ${device.laptop} {
  display: block;
}
`
const Section = styled.div`
position:relative;
max-width:100vw;
height: 100vh;
min-height:35rem;

@media ${device.laptop} {
  display: flex;
}
`

const Polaroid = styled.div`
  width: 60%;
  max-width: 420px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  padding: 8px;

  @media ${device.laptop} {
  display: none;
}
`

const TextContainer = styled.div`
  text-align: center;
  padding: 0 20px;

  p {
    text-align: center;
  }
`