import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled, { css } from 'styled-components'
import Container from '../../cors/Container'
import { device } from '../../cors/ResponsiveSettings'
import categories from '../home/categoriesContent'
import servicesStairs from '../../../assets/images/servicesStairs.jpg'
import servicesStairsTop from '../../../assets/images/servicesStairsTop.jpg'
import servicesStairsBottom from '../../../assets/images/servicesStairsBottom.jpg'
import rcsCoach from '../../../assets/images/rcs_who_i_am.jpg'
import StyledButton from '../../cors/StyledButton'
import StyledLink from '../../cors/StyledLink'



const WhoAmI = ({ element }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const about = categories.about
  return (
    <div ref={element}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Raphael Abouna | Coach sportif</title>
        <meta name="description"
          content="Diplômé d'Etat depuis plusieurs années, j'accompagne mes clients avec avec la plus grande rigueur et le plus grand professionnalisme, qu'ils aient un objectif de perte de poids, de prise de muscle, de remise en forme ou tout simplement de bien-être..." />
      </Helmet>
      <Wrapper>
          <ImageWrapper src={rcsCoach} alt='coach-picture' />
          <Title>RAPHAEL ABOUNA</Title>
          <SubTitle>Coach sportif et mental certifié</SubTitle>
          <ContentWrapper>
            <p>{about.content1}</p>
            <p>{about.content2}</p>
            <p>{about.content3}</p>
            <p>{about.content4}</p>
          </ContentWrapper>
          <BlocWrapper>
            <h2>Mon expertise / Certifications</h2>
            <ul>
              <li>BPJEPS AF Haltérophilie Musculation</li> 
              <li>BPJEPS AF Cours collectifs</li> 
              <li>Préparation Mentale pour la performance sportive</li>
              <li>Précision Nutrition Level 1</li> 
              <li>Pilates mat Niveau 1</li>
            </ul>
          </BlocWrapper>
          <BtnDiv>
            <StyledLink to='/contact'><StyledButton w='15rem' dark>Réserver ma séance d'essai gratuite</StyledButton></StyledLink>
          </BtnDiv>
      </Wrapper>
      {/* <Mobile>
        <Wrapper bgImg={servicesStairsTop}>
          <ContentWrapper textBottom>
            <p>{about.content1}</p>
            <p>{about.content2}</p>
          </ContentWrapper>
        </Wrapper>
        <Wrapper bgImg={servicesStairsBottom} reSize>
          <ContentWrapper textTop>
            <p>{about.content3}</p>
            <p>{about.content4}</p>
          </ContentWrapper>
        </Wrapper>
      </Mobile>
      <Laptop>
        <Wrapper>
        <ImageWrapper src={about.img} alt='coach-picture'/>
          <ContentWrapper>
            <p>{about.content1}</p>
            <p>{about.content2}</p>
            <p>{about.content3}</p>
            <p>{about.content4}</p>
          </ContentWrapper>
        </Wrapper>
      </Laptop> */}

    </div>
  )
}

export default WhoAmI

const Wrapper = styled.div`
  width:100vw;
  padding: 4rem 2rem 2rem 2rem;;
  justify-content:center;
  display:flex;
  flex-direction:column;
  align-items:center;
  /* height:100vh; */
  /* background-image: url(".${props => props.bgImg}"); */
  /* background-size:cover; */
  /* opacity:0.8;
  background-position:50%;  */
  /* color:white; */
  /* min-height:36rem; */

  @media ${device.laptop} {
     padding: 6rem 4rem 3rem 4rem;
  } 
    /* &&::before::after{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.25);
  } */
  /* ${props =>
    props.reSize &&
    css`
    min-height:21rem;
    height:58vh;
      `} */


`

const BtnDiv = styled.div`
  min-height: 4rem;
  height:15%;
`

const BlocWrapper = styled.div`
  box-shadow: -50px 70px 120px -42px rgba(0,0,0,.3);
  padding:2rem;
  margin-bottom: 1rem;
  ul {
    list-style: disc;
  }
  h2 {
    padding: 10px 0;
  }

  @media ${device.laptop} {
     padding: 3rem;
  } 
`

const ImageWrapper = styled.img`
width: 65%;
max-width: 450px;
height: auto;
border-radius: 8px;
`

const Title = styled.h2`
  padding: 10px;
  border-bottom: 2px solid;
`
const SubTitle = styled.h2`
  padding-top: 15px;
`
const ContentWrapper = styled(Container)`
  padding: 2rem 0;
  /* height:100%;
  padding:2rem 1.5rem 0rem 1.5rem;
  background-color:black;
  font-weight:500;
  flex-wrap:nowrap;
  opacity:0.8;
  font-size: calc(1rem + 0.8vw);
  ${props =>
    props.textBottom &&
    css`
    justify-content:flex-end;
  `}
  ${props =>
    props.textTop &&
    css`
    justify-content:start;
      `}
   @media ${device.tablet} {
     padding: 4rem;
     line-height: 3rem;
   }
   @media ${device.laptop} {
    position:relative;
    top:75px;
    min-height:20rem;
    font-size: calc(0.8rem + 0.4vw);
    line-height: calc(1.5rem + 0.8vw);
    font-weight:400;
    height:60vh;
   } */
`

// const ImageWrapper = styled.img`
//   height: 500px;
// `

const Mobile = styled.div`
  @media ${device.laptop} {
    display:none;
  }
`
const Laptop = styled.div`
  display:none;
  @media ${device.laptop} {
    display:block;
  }
`