import React from 'react'
import styled, { css } from 'styled-components'
import StyledLink from '../../cors/StyledLink'
import StyledButton from '../../cors/StyledButton'
import pricesAndOptions from '../pricesAndOptions/pricesContent'
import { device } from '../../cors/ResponsiveSettings'
import { CircleInformation } from 'grommet-icons'

const Prices = ({content}) => {

    // const prices = []
    // Object.keys(pricesAndOptions).forEach(option => prices.push(pricesAndOptions[option]))

  return (
    <Wrapper>
        <Bubble>
        <h1>Tarifs et formules</h1>
        <BlocWrapper>
            {Object.entries(pricesAndOptions).map(([type, content])=> 
              <StyledLink to={`/prices#${type}`} padding='0' key={type}>
                <Bloc>
                  <h3>{content.title}</h3>
                  <SubHeader>
                    <PriceWrapper>
                      <h5>{content.price} €</h5>
                      <h3>{content.price / 2} €</h3>
                    </PriceWrapper>
                    <p>Après réduction d'impôt <span><StyledLink to='/taxes' padding='0'><CircleInformation size='small' color='green' /></StyledLink></span></p>
                  </SubHeader>
                </Bloc> 
              </StyledLink>
            )}
        </BlocWrapper>
        <StyledLink to={content.linkTo}><StyledButton mobile>En savoir plus</StyledButton></StyledLink>
        </Bubble>
    </Wrapper>
  )
}

export default Prices

const Wrapper = styled.div`
// display square
height: auto;

width:100vw;

//display décalé
//height:100vh;

padding: 2rem 1rem;
// font-size: calc(1rem +0.6vw);
/* position: absolute;
top:0;
z-index:10; */
//font-weight:400;
h1{
  color: black;
  font-size: calc(2rem + 0.5vw);
  font-weight: 400;
  padding:0.5rem 1rem;
}
p{
  padding: 0.5rem 1rem;
  /* overflow-x:auto; */
  /* min-height: 7rem; */
}

@media ${device.laptop} {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding:4rem;
  position:relative;
  color:black;
  font-weight:300;
  line-height:2;
  align-items:center;
  text-align:center;
    height: auto;
}`

const Bubble = styled.div`
  background-color: #edf2f4;
  height: 100%;
  border-radius: 25px;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

const Bloc = styled.div`
  height: 10rem;
  width: 12rem;
  box-shadow: 2px 2px 10px grey;
  /* border: solid 1px #5F7470; */
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #f8f7ff;

  // display square
  margin:2px;

      @media ${device.laptop} {
        margin: 1rem;
    &:nth-child(1) { 
        position: initial;
    }
    &:nth-child(2) { 
        position: initial;
    }
    &:nth-child(3) { 
        position: initial;
    }
    &:nth-child(4) { 
        position: initial;
    }
}
`

const BlocWrapper = styled.div`
  display: flex;
  // display décalé
  //flex-direction: column;
  //padding: 2rem;
  //height: 80%;

  // display square
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 0;
  height: auto;
  justify-content: center;

  align-items: center;
  width: 100%;
 
  
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
}
`

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    padding: 4px;
  }
  h5 {
    padding: 4px;
    text-decoration: line-through;
  }
`

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  
  line-height: 1;
  p {
    font-size: 0.7rem;
    text-align: center;
  }
`