const taxes = [
   {
    title: 'Qu’est-ce que c’est ?',
    text: 'C’est un dispositif mis en place par l’état permettant aux personnes faisant appel à un service à domicile de pouvoir déduire 50% du prix des prestations effectuées de leurs impôts. Les dépenses que vous engagerez en faisant appel à mes services de coaching vous donneront le droit à un crédit d’impôt de 50% lors de votre déclaration auprès de l’administration fiscale.'
  },
 {
    title: 'Comment ça marche ?',
    text: 'Vous recevrez une facture pour chaque paiement ainsi qu’une attestation fiscale chaque début d’année en janvier. Vous n’avez plus qu’à reporter le montant figurant dans cette attestation dans votre déclaration d’impôts pour bénéficier de la déduction de 50%.',

  },
  {
    title: 'Qui peut en bénéficier ?',
    text: 'Les contribuables exerçant une activité professionnel et les demandeurs d’emploi sous certaines conditions. Le crédit d’impôt est débité du montant dû ou prends la forme d’un remboursement.'
  }
]

export default taxes