import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'


const StyledLink = styled(NavLink)`
  color: inherit;
  text-decoration:none;
  cursor: pointer;
  text-align: center;
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || '0 1rem'};
  &:hover{
    text-decoration:none;
    color: #0096c7;
  }
  &.active {
    color: #0096c7;
  }

  ${props =>
    props.nav &&
    css`
      padding: 0 0.5rem;
    `}
`;

export default StyledLink
