import React from 'react'
import styled, { css } from 'styled-components'
import StyledLink from '../../cors/StyledLink'
import StyledButton from '../../cors/StyledButton'
import services from '../services/servicesContent'
import { device } from '../../cors/ResponsiveSettings'

export const Services = ({content}) => {

    const objectives = []
    console.log('objkectives => ', objectives)
    Object.entries(services).map(([type, content])=> console.log('type => ', type, 'content => ', content))
    Object.keys(services).forEach(target => objectives.push(services[target]))

  return (
    <Wrapper>
        <Bubble>
        <h1>Prestations</h1>
        <BlocWrapper>
            {Object.entries(services).map(([type, content]) => 
            // <Bloc display={['Perte de poids', 'Pilates'].includes(objective.title) ? 'right' : 'left' }>
            //     <h3>{objective.title}</h3>
            //     <Icon src={objective.icon} />
            // </Bloc> 
            <StyledLink to={`/services#${type}`} padding='0' key={type}>
              <Bloc>
                <h3>{content.title}</h3>
                <Icon src={content.icon} />
              </Bloc>
            </StyledLink>
            )}
        </BlocWrapper>
        <StyledLink to={content.linkTo}><StyledButton mobile>En savoir plus</StyledButton></StyledLink>
        </Bubble>
    </Wrapper>
  )
}

export default Services

const Wrapper = styled.div`
  // display square
  height: auto;

width:100vw;

//display décalé
//height:100vh;

padding: 2rem 1rem;
// font-size: calc(1rem +0.6vw);
/* position: absolute;
top:0;
z-index:10; */
//font-weight:400;
h1{
  color:black;
  font-size: calc(2rem + 0.5vw);
  font-weight: 400;
  padding:0.5rem 1rem;
}
p{
  padding: 0.5rem 1rem;
  /* overflow-x:auto; */
  /* min-height: 7rem; */
}

@media ${device.laptop} {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding:4rem;
  position:relative;
  color:black;
  font-weight:300;
  line-height:2;
  align-items:center;
  text-align:center;
    height: auto;
}`

const Bubble = styled.div`
  background-color: #d8e2dc;
  height: 100%;
  border-radius: 25px;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

const Bloc = styled.div`
  height: 10rem;
  width: 10rem;
  box-shadow: 5px 5px 15px grey;
  /* border: solid 1px #5F7470; */
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #f8f7ff;
  cursor: pointer;

  //display décalé
  /* &:nth-child(1) { 
        position: relative;
        right:2rem;
    }
    &:nth-child(2) { 
        position: relative;
        left:2rem;
        bottom: 1rem;
    }
    &:nth-child(3) { 
        position: relative;
        right:4rem;
        bottom: 2rem;
    }
    &:nth-child(4) { 
        position: relative;
        left:3rem;
        bottom: 3rem;
    } */
    /* ${props =>
    props.display === 'left' &&
    css`
        position: relative;
        right:2rem;
      `}
      ${props =>
    props.display === 'right' &&
    css`
        position: relative;
        left:2rem;
        bottom: 1rem;
      `} */


  // display square
  margin:2px;

      @media ${device.laptop} {
        margin: 1rem;
    &:nth-child(1) { 
        position: initial;
    }
    &:nth-child(2) { 
        position: initial;
    }
    &:nth-child(3) { 
        position: initial;
    }
    &:nth-child(4) { 
        position: initial;
    }
    width: 20rem;
}
`

const BlocWrapper = styled.div`
  display: flex;
  max-width: 65rem;
  // display décalé
  //flex-direction: column;
  //padding: 2rem;
  //height: 80%;

  // display square
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 0;
  height: auto;
  justify-content: center;

  align-items: center;
  width: 100%;
 
  
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
}
`

const Icon = styled.img`
  width: 3rem;
  padding: 1rem;
  @media ${device.laptop} {
    width:3rem;
  }
`