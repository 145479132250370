import React from 'react'
import { EmbeddedTypeform } from './EmbeddedTypeForm'
import styled from 'styled-components'
import { Clock, FormClock } from 'grommet-icons';
import { device } from '../../cors/ResponsiveSettings'
import './TypeForm.css'

const TypeForm = props => {

    const { element } = props

    return (
        <Wrapper ref={element}>
            <Title>Prise de contact ou réservation d'une séance d'essai gratuite</Title>
            <Text>Quelques questions afin que je puisse vous contacter</Text>
          <EmbeddedTypeform link='b2HhDo9i' />
            <SubText><FormClock color="white"/>Prend moins de 30 secondes</SubText>
        </Wrapper>
      )
}


export default TypeForm

const Wrapper = styled.div`
  max-width:100vw;
  height:90vh;
  background-color: #03071e;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  padding:3rem;
  color: #fff;
  text-align: center;
  @media ${device.laptop} {
    padding:6rem;
  }
 
`

const Title = styled.h2`
    padding: 1rem;
`

const Text = styled.p`
    text-align: center;
    padding: 1rem;
`

const SubText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
`