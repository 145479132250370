const prices = {
  oneSession: {
    title: 'Séance unique',
    price: 100
  },
  // fiveSessions: {
  //   title: 'Pack 5 séances',
  //   price: 475
  // },
  tenSessions: {
    title: 'Pack 10 séances',
    price: 900
  },
  nutrition: {
    title: 'Programme nutrition',
    price: 100
  }
  // groupSession: {
  //   title: 'Séance en groupe',
  //   price: 'Base 100',
  //   priceOption: '+ 20€/pers supplementaire',
  //   // option: 'max 4 personnes',
  //   display: 'group'
  // }
}

export default prices