import React from 'react'
import ReactDOM from 'react-dom'
import "@csstools/normalize.css"
import "reset-css"
import App from './App'
import GlobalStyle from './globalStyle'
import * as serviceWorker from './serviceWorker'
import BlogProvider from './context/BlogContext'
import UserProvider from './context/UserContext'
import AuthProvider from './context/AuthContext'

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    {/* <AuthProvider> */}
    <BlogProvider>
      {/* <UserProvider> */}
      <App />
      {/* </UserProvider> */}
    </BlogProvider>
    {/* </AuthProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
