import React, { useEffect, useRef } from 'react';
import { Widget, SliderButton, PopupButton } from '@typeform/embed-react'

export const EmbeddedTypeform = () => {




  return (
    <PopupButton id="b2HhDo9i" className='popup-btn'>
        C'est parti!
       </PopupButton>
  );
};