import React from 'react'
import { withRouter } from 'react-router'
import StyledLink from '../cors/StyledLink'
import styled, { keyframes, css } from 'styled-components'
import { device } from '../cors/ResponsiveSettings'
import logoLight from '../../assets/icons/logo-raph-light-copie.png'
import logoDark from '../../assets/icons/logo-raph-sans-fond-dark-copie.png'



const Navbar = (props) => {

  const { sticky, location } = props

  return (

    <Wrapper cl={sticky ? 'navbarSticky' : 'navbar'} mode={location.pathname === '/contact' ? 'dark' : null}>
      {/* <LogoContainer> */}
      <StyledLink exact to='/' onClick={() => window.scrollTo(0, 0)}><Logo src={sticky ? logoDark : logoLight} /></StyledLink>
      {/* </LogoContainer> */}
      <NavContainer>
        <StyledLink nav exact to='/'>Accueil</StyledLink>
        <StyledLink nav to='/about'>Votre coach</StyledLink>
        <StyledLink nav to='/services'>Prestations</StyledLink>
        <StyledLink nav to='/prices'>Tarifs et formules</StyledLink>
        <StyledLink nav to='/taxes'>Services à la personne</StyledLink>
        <StyledLink nav to='/blog'>Blog</StyledLink>
        <StyledLink nav to='/contact'>Me contacter</StyledLink>
      </NavContainer>
    </Wrapper>

  )
}

export default withRouter(Navbar)

const NavContainer = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.img`
  position: fixed;
  top: 30px;
  left: 30px;
  width: 1.5em;
`
const moveDown = keyframes`
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
`
const Wrapper = styled.nav`
    font-size: calc(0.7rem + 0.5vw);
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    height: 7vh;
    z-index: 100;
    background:transparent;
    align-items:center;
    box-shadow: 0 0 7px rgba(0,0,0,.1);

  @media ${device.laptop} {
    display:flex;
    min-height: 65px;
  }

    ${props =>
    props.cl === 'navbar' &&
    css`
      background: transparent;
    `}
    ${props =>
    props.cl === 'navbarSticky' &&
    css`
      width:100vw;
      background: #f7f7ff; //#f7f7ff
      color:black;
      animation: ${moveDown} 0.5s ease-in-out;
    `}
    ${props =>
    props.mode === 'dark' &&
    css`
      color:white;
    `}

      
`