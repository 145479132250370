import React from 'react'
import styled from 'styled-components'
import Container from '../../cors/Container'
import CatTitle from '../../cors/CatTitle'
import { device } from '../../cors/ResponsiveSettings'
import ReviewSlide from './ReviewSlide'


const Review = () => {


  return (
    <Wrapper>
      {/* <CatTitle> */}
        <h6>Leur retour d'expérience</h6>
      {/* </CatTitle> */}
      <Laptop>
        <ReviewSlide display='laptop' />
      </Laptop>
      <Mobile>
        <ReviewSlide display='mobile' />
      </Mobile>
    </Wrapper>
  )
}

export default Review


const Wrapper = styled.div`
  min-height:40rem;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: #03071e ; //#f1faee
  h6{
    text-align: center;
    font-size: 1.8rem;
    color: white;
    padding: 15px;
  }
  @media ${device.laptop} {
    min-height: 25rem;
    height: 60vh;
  }
`
const Laptop = styled.div`
  display:none;
  height: 88%;
  @media ${device.laptop} {
    display:block;
    line-height: 1.5;
    /* height:55vh; */
  }
`
const Mobile = styled.div`
  display:block;
  height: 90%;
  @media ${device.laptop} {
    display:none;
  }
`