import React from 'react'
import { useLocation } from "react-router-dom";
import { bool, func } from 'prop-types'
import { StyledBurger } from '../cors/StyledBurger'

const Burger = ({ open, setOpen }) => {

  const location = useLocation();


  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)} onContact={location.pathname === '/contact'}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger