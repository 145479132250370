import muscle from '../../../assets/icons/muscle.png'
import weightScale from '../../../assets/icons/weightScale.png'
import trophy from '../../../assets/icons/trophy2.png'
import pilates from '../../../assets/icons/pilates.png'
import sport from '../../../assets/icons/sport.png'

const services = {
  getInShape: {
    icon: sport,
    title: 'Remise en forme',
    content1: 'Programme de remise en forme progressif adapté à votre condition physique.',
    content2: 'Les séances sont composées d’exercices permettant l’amélioration des différentes qualités physiques comme la force, la souplesse, la coordination et l’endurance.'
  },
  loseWeight: {
    icon: weightScale,
    title: 'Perte de poids',
    content1: `Programme sur mesure adapté à votre condition physique.`,
    content2: `Les séances seront basées sur un mélange de travail cardio et du renforcement musculaire permettant une importante dépense calorique (et le maintien/la prise de masse musculaire)`,

  },
  getMuscle: {
    icon: muscle,
    title: 'Renforcement musculaire',
    content1: `Programme sur mesure visant à se muscler et à se tonifier.`,
    content2: `Les séances seront axées autour d'exercices qui vous permettront de vous muscler efficacement, le plus souvent effectuées contre résistance, à l'aide de matériel tel que des haltères, des elastiques ou encore des sangles de suspension.`
  },
  pilates: {
    icon: pilates,
    title: 'Pilates',
    content1: `Méthode d’entrainement douce visant le renforcement des muscles profonds et posturaux.`,
    content2: `Le Pilates permet le renforcement de l’ensemble du corps de façon harmonieuse, grâce à des techniques basées sur la respiration, la mobilité articulaire et une meilleure utilisation des muscles superficiels.`
  }
}

export default services