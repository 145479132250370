import whoAmI1400 from '../../../assets/images/whoAmI_w_1400.jpg'
import whoAmI1400Cut from '../../../assets/images/whoAmI_w_1400_cut.jpg'
import servicesRaw from '../../../assets/images/servicesRaw.jpg'
import pricesRaw from '../../../assets/images/pricesRaw.jpg'

const categories = {
  about: {
    title: 'Votre coach',
    content1: `C'est avant tout avec passion et détermination que j'évolue dans le domaine sportif depuis mon plus jeune âge. J’ai notamment pratiqué le football et le tennis en compétition, domaine dans lesquels j’ai également été éducateur.`,
    content2: `Suite à l’obtention du BPJEPS en 2019, je me suis lancé exclusivement dans le coaching sportif individuel. J’accompagne au quotidien de nombreuses personnes vers la réalisation de leurs objectifs.`,
    content3: `Fort de plusieurs années d’expériences, et ayant accompagné tous types de profil, je suis à même de vous proposer des entraînements personnalisés qui s’adapteront à vos objectifs, vos besoins et vos attentes.`,
    content4: `Ma soif d’apprendre et de vous offrir le meilleur service possible me poussent à constamment développer mes compétences et me tenir informé des dernières avancées dans le monde du sport. C’est pour ces raisons que j’ai obtenu la certification en Pilates (mat niveau 1) en 2020 ainsi qu’en préparation mentale pour la performance sportive en 2021.`,
    linkTo: `./about`,
    img: whoAmI1400Cut,
    bgPos: 'top'
  },
  services: {
    title: 'Prestations',
    content1: `De la perte de poids à la prise de masse, en passant par la réathlétisation, je vous propose un programme d'entraînement personnalisé qui s'adapte à vos objectifs, vos besoins, sans oublier vos contraintes.`,
    linkTo: `./services`,
    img: servicesRaw,
    switchDisplay: true
  },
  prices: {
    title: 'Tarifs et formules',
    content1: `Différentes formules adaptées à vos objectifs, séance unique, en groupe ou forfait...`,
    linkTo: `./prices`,
    img: pricesRaw
  }
}

export default categories