import styled, { css } from 'styled-components'
import { device } from "./ResponsiveSettings";


export const StyledBurger = styled.button`
  position: fixed;
  top: 2.5%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index:200;

  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2rem;
    height: 0.15rem;
    background: ${({ theme, open, onContact }) => open || onContact ? 'white' : 'black'};  
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;



    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(-20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  ${props =>
    props.onContact &&
    css`
      div {
        background: white;
      }
      
    `}

  @media ${device.laptop} {
    display:none;
    /* div{
      background: black; 
    } */
   
  }
`;