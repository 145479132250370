import React, { useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../cors/ResponsiveSettings'
import taxes from './taxesContent'
import Container from '../../cors/Container'
import sapLogo from '../../../assets/icons/logoSAP.jpg'
import sapIcons from '../../../assets/icons/services-personne-reduction.png'
import StyledLink from '../../cors/StyledLink'
import StyledButton from '../../cors/StyledButton'


const Taxes = ({ element }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
      <Wrapper ref={element}>
        <ImageWrapper src={sapIcons} alt='logo-service-a-la-personne' />
        <PageWrapper>
          <BlocWrapper>
            {taxes.map(({title, text}) => (
              <ContentWrapper>
                <h2>{title}</h2>
                <p style={{paddingBottom: '15px'}}>{text}</p>
              </ContentWrapper>
            ))} 
          </BlocWrapper>
          <ExampleWrapper>
            <h2>Exemple</h2>
              <p>Vous avez pris 2 packs de 10 séances pour un montant total de 1800€ sur l’année 2023.</p> 
              <p>Etant donné que vous êtes remboursé à hauteur de 50% : </p>
              <SubHeader>Cas n°1</SubHeader> 
              <p>Vous ne payez pas d’impôts, l’état vous enverra un chèque de 900€</p>
              <SubHeader>Cas n°2</SubHeader>
              <p>Vous devez payer 2000€ d’impôt. Après déduction des 900€, vous ne paierez plus que 1100€ d’impôts.</p>
              <p>Vous devez reporter le montant figurant sur l’attestation fiscale que je vous fournis en janvier de chaque année dans la case 7DF ou 7DB de votre déclaration de revenue.</p>
              <p>Pour en savoir plus, n’hésitez pas à consulter cette <a href="https://www.urssaf.fr/portail/home/services-a-la-personne.html">page</a>.</p>  
          </ExampleWrapper>
        </PageWrapper>
        <BtnDiv>
            <StyledLink to='/contact'><StyledButton w='15rem' dark>Réserver ma séance d'essai gratuite</StyledButton></StyledLink>
          </BtnDiv>
      </Wrapper>
  )
}

      {/* <Laptop>
      {taxes.map(({title, text}) => (
        <>
          <h2>{title}</h2>
          <p style={{paddingBottom: '15px'}}>{text}</p>
        </>
      ))} 
      <h3>Exemples</h3>
        <p>Vous avez pris 2 packs de 10 séances pour un montant total de 1400€ sur l’année 2021.</p> 
        <p>Etant donné que vous êtes remboursé à hauteur de 50% : </p>
        <li>Cas n°1 : vous ne payez pas d’impôts, l’état vous enverra un chèque de 700€</li>
        <li>Cas n°2 : Vous devez payer 1000€ d’impôt. Après déduction des 700€, vous ne paierez plus que 300€ d’impôts.</li> 
        <p>Vous devez reporter le montant figurant sur l’attestation fiscale que je vous fournis en janvier de chaque année dans la case 7DF ou 7DB de votre déclaration de revenue.</p>
        <p>Pour en savoir plus, n’hésitez pas à consulter cette <a href="https://www.urssaf.fr/portail/home/services-a-la-personne.html">page</a>.</p>
      </Laptop> */}

export default Taxes


const Wrapper = styled(Container)`
  width: 100vw;
  padding: 1rem;

  a {
    text-decoration: none;
  }

  h3 {
    padding: 1rem;
  }
  p{
    padding-top: 10px;
  }

  @media ${device.laptop} {
    min-height:50em;
    position: relative;
    top: 75px;
    width: 100vw;
    height: 100vh;
    padding: 1rem 3rem;
    justify-content: flex-start;
  }
`

const BtnDiv = styled.div`
  min-height: 4rem;
  height:15%;
`

const ContentWrapper = styled.div`
  border-radius: 25px;
  box-shadow: 0px 0px 12px grey;
  margin: 1rem;
  padding: 1rem;
  h2 {
    padding: 10px;
    text-align: center;
  }

  @media ${device.laptop} {
    width: 90%;
    margin: 0.5rem;
    h2 {
    padding: 2px;
    font-size: 1rem;
    }
    p {
    font-size: 0.7rem;
    line-height:1.5;
    }
  }
`

const BlocWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media ${device.laptop} {
    margin: 1rem;
    width: 70%;
  }
`

const ExampleWrapper = styled.div`
  padding: 1rem;
  text-align: center;

  @media ${device.laptop} {
    width: 30%;
    h2 {
    padding: 2px;
    font-size: 1rem;
  }
  p{
    font-size: 0.7rem;
    line-height:1.5;
  }
  background-color: #edf2f4;
  border-radius: 10px;
  }
`

const SubHeader = styled.p`
  /* padding-top: 10px; */
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
    max-height: 80%;
  }
`

const ImageWrapper = styled.img`
  width: 14rem;

  @media ${device.laptop} {
    max-height: 20%;
  }
`
